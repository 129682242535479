.mainpage-section {
    background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    .lottie-container {
        max-width: 1670px;
        padding: 0;
        position: relative;
        min-height: 16vh;
        margin-top: -100px;
        .start-lottie {
            display: flex;
            justify-content: center;
            align-items: center;
            
            .lf-player-container {
                width: 40%;
                margin-left: 160px;
                #lottie-start {
                    height: auto;
                    width: 100%;
                    margin: 0!important;
                }
                @media (min-width: 560px) and (max-width: 1520px){
                    width: 45%;
                }
                
            }
        }
        @media (max-width: 559.9px){
            .start-lottie {
                right: -75px;
                .lf-player-container {
                    width: 60%;
                
                }
            }
        }
    }
    .main-page-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        min-height: 84vh;
        align-items: center;
        .skip-button {
            height: 38px;
            width: 107px;
            border-radius: 26px;
            background-color: rgba(255,255,255,0.11);
            box-shadow: none!important;
            border: none;
            color: #ffffff;
            img {
                
                width: 16.57px;
                margin-left: 10px;
            }
            @media (max-width: 992px){
                height: 59px;
                max-width: 116px;
                background-color: rgba(255,255,255,0.2);
                margin-right: 10px;
            }
        }
        .wehelpline {
            position: absolute;
            bottom: 49px;
            left: -288px;
            img {
                
                width: 95%;
            }
        }
        .title-part {
            position: relative;
            z-index: 1020;
            margin: -60px 10px 0 10px;
            opacity: 1;
            transition: opacity .45s linear  0.5s;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            span {
                //height: 35px;
                max-width: 840px;
                color: #FFFFFF;
                font-size: 64px;
                font-weight: normal;
                letter-spacing: -0.83px;
                line-height: 72px;
                text-align: center;
                opacity: 1;
            }
            &.hover {
                opacity: 0;
                transition: opacity .25s linear;
            }
        }
        .partners-part {
            max-width: 1560px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 20px;
            margin-top: -176px;
            .layout-div {
               position: relative;
               padding: 0 35px 35px 0;
               margin: 0 15px;

                &::before {
                    content: "";
                    position: absolute;
                    inset: 0;
                    border-radius: 0 0 70px 0px;
                    padding: 0 25px 25px 0;
                    background: linear-gradient(59deg, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0.72) 50.73%, rgba(255, 255, 255, 0) 94%);
                    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                        mask-composite: add, add;
                    mask-composite: add, add;
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                    pointer-events: none;
                    opacity: 0;
                    transition: opacity 0.25s linear 0.1s;
                }

             &.hover{
                &::before {
                    opacity:1;
                    transition: opacity .3s linear 0.1s;
                }
             }
            }
            .first-anim {
                opacity: 0;
                transition: opacity .3s linear 0.1s;
                //position: relative;
                &.hover{
                    opacity: 1;
                    transition: opacity .3s linear 0.1s;
                }
                .video-text {
                    position: relative;
                    
                    .anim-text {
                        margin-left: 40px;
                        position: absolute;
                        top: 50%;
                        left: 15px;
                        transform: translate(0,-50%);
                        padding-right: 47px;
                        padding-top: 12px;
                        padding-bottom: 15px;
                        opacity: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                        transition: opacity 0.45s linear 0.5s;
                       
                        span {
                            //height: 144px;
                            width: 810px;
                            color: #FFFFFF;
                            font-size: 64px;
                            font-weight: normal;
                            letter-spacing: -0.83px;
                            line-height: 72px;
                            
                        }

                        &.right {
                            left: unset;
                            right: 0px;
                            span {
                                text-align: right;
                            }
                        }
                    
                        &.hover {
                            opacity: 1;
                            transition: opacity .6s linear 0.3s;
                        }
                    }
                }
               
            }
            .partner-button{
                position: relative;
                z-index:100;
                height: 107px;
                width: 100%;
                border-radius: 56px 0 41px 41px;
                background-color: rgba(255,255,255,0.11);
                display: flex;
                align-items: center;
                justify-content: end;
                flex-direction: row;
                padding-right: 20px;
                margin-top: 25px;
                cursor: pointer;
                transition: all .45s linear  0.5s;
                &:hover {
                    height: 132px;
                    border-radius: 0 0 41px 41px;
                    //background-color: #1F80F0;
                    margin-top: 0;
                }
                &.first{
                    &:hover {
                        background-color: #028bf2;
                    }
                }
                &.second{
                    &:hover {
                        background-color: #028bf2;
                    }
                }
                &.third{
                    &:hover {
                        background-color: #019AF4;
                    }
                }
                &.fourth{
                    &:hover {
                        background-color: #01A7F6;
                    }
                }
                .description-text {
                    width:  100%;
                    max-width: 130px;
                    color: #FFFFFF;
                    font-size: 22px;
                    letter-spacing: 0.36px;
                    line-height: 35px;
                    text-align: right;
                    max-height: 500px;
                    opacity: 1;
                    transition: all 0.3 linear;
                }
                .anim-button {
                    height: 60px;
                    width: 96px;
                    background-color: transparent;
                    box-shadow: none!important;
                    border: none;
                    margin-left: 15px;
                    img {
                        height: 60px;
                        width: 96px;
                    }
            }
            }
            
        }
        @media (min-width: 992px) and (max-width: 1520px){
            .title-part {
               
                span {
                    font-size: 52px;
                    line-height: 60px;
                }
                &.hover {
                    opacity: 0;
                    transition: opacity .45s ease-out;
                }
            }
            .partners-part {
                margin-top: -126px;
                .first-anim {
                    .video-text {
                        .anim-text {
                            margin-left: 40px;
                            top: 50%;
                            left: 15px;
                            padding-right: 47px;
                            padding-top: 12px;
                            padding-bottom: 15px;
                           
                            span {
                                max-width: 500px;
                                font-size: 38px;
                                line-height: 45px;
                                
                            }
    
                        }
                    }
                   
                }
                .partner-button{
                    height: 87px;
                    border-radius: 21px 0 25px 25px;
                    padding-right: 10px;
                    margin-top: 25px;
                    &:hover {
                        height: 112px;
                        border-radius: 0 0 41px 41px;
                    }
                    .description-text {
                        font-size: .7rem;
                        line-height: 17px;
                    }
                    .anim-button {
                        height: 35px;
                        width: 50px;
                        margin-left: 5px;
                        img {
                            height: 35px;
                            width: 50px;
                        }
                }
                }
                
            }
        }
        .lottie-container-respo {
            width: 100%;
            
            .first-anim {
                .anim-button {
                        img {
                            height: 50px;
                            width: 85px;
                        }
                }
                .anim-text {
                    padding-right: 25px;
                    span {
                        height: 18px;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 16px;
                    }
                }
            }
        }
    }
    @media (max-width: 992px){
        .main-page-center {
            margin-top: -40px;
            .title-part {
                margin-top: 15px;
                span {
                    font-size: 30px;
                    line-height: 35px;
                }
            }
        }
        .first-anim-respo {
            .description-text {
                width:  100%;
                color: #FFFFFF;
                font-size: 22px;
                letter-spacing: 0.36px;
                line-height: 35px;
                text-align: center;
                max-height: 500px;
                opacity: 1;
                transition: all 0.3 linear;
            }
            .anim-text {
                margin-top: -20px;
                position: relative;
                span {
                    height: 57px;
                    max-width: 337px;
                    color: #FFFFFF;
                    font-size: 18px;
                    font-weight: 800;
                    letter-spacing: 0.36px;
                    line-height: 24px;
                    text-align: center;
                    
                }
                .slide-anim {
                    //position: absolute;
                    //right: 0;
                    //top: -35px;
                    //transform: translateY(-50%);
                    width: 100%;
                }
            }
           
           
        }
        .anim-button{
            height: 60px;
            width: 100%;
            background-color: #FF6701;
            box-shadow: none!important;
            border: none;
            border-radius: 30px;
            //margin-top: 30px;
            @media (max-width: 992px){
                width: 70%;
            }
            img {
                width: 45px;
                @media (max-width: 992px){
                    width: 24.16px;
                    margin-left: 15px;
                }
            }
            .description-text {
                width:  100%;
                max-width: 130px;
                color: #FFFFFF;
                font-size: 22px;
                letter-spacing: 0.36px;
                line-height: 35px;
                text-align: right;
                max-height: 500px;
                opacity: 1;
                transition: all 0.3 linear;
                @media (max-width: 992px){
                    //height: 59px;
                    width: 125px;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.48px;
                    line-height: 17px;
                    text-align: right;
                    display: flex;
                }
            }
           
        }
    }
   

}