@font-face {
  font-family: 'Satoshi';
  src: url('./Satoshi-Regular.woff2') format('woff2'),
       url('./Satoshi-Regular.woff') format('woff'),
       url('./Satoshi-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./Satoshi-Medium.woff2') format('woff2'),
       url('./Satoshi-Medium.woff') format('woff'),
       url('./Satoshi-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./Satoshi-Bold.woff2') format('woff2'),
       url('./Satoshi-Bold.woff') format('woff'),
       url('./Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}
