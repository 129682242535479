@import url('../media/fonts/font-awesome/css/all.min.css');
@import url('../media/fonts/satoshi/satoshi.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800');



.noselect {
  
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  //background: #E8ECEE;
  background: #F6F8F9;
  //background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
  min-height: 100vh;
  font-family: 'Manrope'!important;
  overflow-x: hidden;
}


.container {
  max-width: 1600px;
  //width: auto;
  margin-left: auto;
  margin-right: auto;
}

.scrollbar-container {
  height: 100%!important;
}

html {
	height: 100vh;
  scroll-behavior: smooth;
}





//pages
//@import "header/header.scss";
@import "navbar/navbar.scss";
@import "pages/homepage.scss";
@import "pages/teampage.scss";
@import "pages/mainpage.scss";

@import "elements/carousel.scss";

// VARIABLES
$brown: #c8866e;
$red: #dc4e4e;

// FUNCTIONS

  *, *::after, *::before {
    box-sizing: border-box;
  }


  :root {
    --slider-padding: 5rem;
    --slider-index: 0;
    --slider-rest: 0;
  }

  .carousel-caption {
    display: grid !important;
    bottom: unset;
    top: 0;
    left: 0;
    right: 0;
  }

  .carousel-item {
    padding-top: 7rem !important
  }

  .carousel-dark .carousel-indicators [data-mdb-target] {
    height: 10px;
    width: 10px;
    background-color: #E9E9E9;
    border-radius: 50%;
    opacity: 0.9;
    margin-right: 6px;
    margin-left: 6px;
    border: none!important;
    &.active{
      opacity: 1;
      height: 14px;
      width: 14px;
      background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
      border-radius: 50%;
      border: none!important;
      margin-top: -2px;
    }
  }

  .carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    border: none!important;
    box-shadow: none;
    background-color: #E9E9E9;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    opacity: 0.9;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
    &.selected{
      opacity: 1;
      height: 14px;
      width: 14px;
      background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
      border-radius: 50%;
      border: none!important;
      margin-top: -2px;
    }
  }

  .carousel-indicators {
    bottom: -12px;
    margin: 0 !important;
  }

  .carousel-inner {
    overflow: unset;
  }


  @media (min-width: 576px){
    #faqmodal .modal-dialog {
      max-width: 800px!important;
      margin: 1.75rem auto;
    }
  }
  
  .example-style {
    animation: fade-in 3s;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }

  .anim-div {
    height: 368px;
    width: 421.49px;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%,-50%);
    box-shadow: 0 12px 20px 0 rgba(255,255,255,0.04); 
}

ul li{
  list-style: none;
}

ul.navbar-nav.w-100 {
 width: auto !important;
}
