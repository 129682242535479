.main-navbar {
    .fixed-main-navbar{
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2000;
    }
    .navbar-brand {
      list-style: none;
      a {
        position: relative;
        color: #F6F8F9;
        font-size: 16px;
        letter-spacing: 0.48px;
        line-height: 17px;
        text-align: center;
        opacity: 0.9;
        &.en-item {
            width: 35px;
            margin-right: 0 !important;
            &::after {
                background: #ffffff !important;
                position: absolute;
                margin: auto;
                z-index: 1;
                content: "";
                width: 2px;
                height: 16px;
                top: 8px;
                right: -10px;
                opacity: 1;
            }
        }
        &.de-item {
            width: 35px;
            margin-right: 0;
        }

        &:hover {
            opacity: 1;
            font-size: 17px;
            color: #ffffff !important;
            font-weight: bold!important;
        }

        &.active {
            opacity: 1;
            color: #ffffff !important;
            font-size: 17px;
            font-weight: bold!important;
        }
        
    }
    }
    .navbar {
      height: 99.8px !important;
      width: 100% !important;
      background: transparent;
      box-shadow: none;
      #teampage {
        justify-content: start;
      }
      @media (max-width: 991.9px){
        .container {
            justify-content: end;
        }
        #mainpage {
          justify-content: start;
        }
        
      }
      &.scroll {
        background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
        box-shadow: 0 4px 9px 5px rgba(0,0,0,.08);
      }
      opacity: 1;
      z-index: 2000;
      padding-bottom: 0;
      padding-top:0;
      
    }
    .navbar-light .navbar-nav {
        display: table;
        float: right !important;
        a {
            position: relative;
            width: 150px;
            margin-right: 30px;
            color: #F6F8F9;
            //font-family: Manrope;
            font-size: 16px;
            letter-spacing: 0.48px;
            line-height: 17px;
            text-align: center;
            opacity: 0.9;
            &.en-item {
                width: 35px;
                margin-right: 0 !important;
                &::after {
                    background: #ffffff !important;
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    width: 2px;
                    height: 16px;
                    top: 8px;
                    right: -2px;
                    opacity: 1;
                }
                &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 0 !important;
                    border-radius: 12px;
                    background-color: #FFC233;
                }
            }
            &.de-item {
                width: 35px;
                margin-right: 0;
                &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 0 !important;
                    border-radius: 12px;
                    background-color: #FFC233;
                }
            }
            span {
                font-weight: 500!important;
                letter-spacing: 1.6px!important;
            
            }
    
            &:hover {
                opacity: 1;
                font-size: 17px;
                color: #ffffff !important;
                font-weight: bold!important;
            }
    
            &.active {
                opacity: 1;
                color: #ffffff !important;
                font-size: 17px;
                font-weight: bold!important;
                &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 23.38px;
                    border-radius: 12px;
                    background-color: #FFC233;
                }
            }
            
        }
    }

    .sidenav-bottom-respo {
        position: fixed;
        width: 100%;
        top: 0;
        height: 125px;
        z-index: 3000;
        //background: rgba(145, 231, 225) !important;
        .close-button {
          background-color: transparent;
          padding: 0;
          //margin: 40px 35px 0 0;
          width: auto;
          box-shadow: none!important;
          position: relative;
          img {
            height: 23.69px;
            width: 23.69px;
          }
        }
        .sidenavButton {
          background-color: transparent;
          padding: 0;
          margin: 0 35px 0 0;
          width: auto;
          box-shadow: none!important;
          position: relative;
          border-radius: 0;
          //z-index: 1020;
          &.scroll {
            height: 67.17px;
            width: 67.17px;
            border-radius: 50%;
            background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
            margin-right: 17px;
            margin-top: 17px;
          }
        }
        .title-text span {
          margin: 10px 0;
          color: #FFF;
          text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          //font-family: Manrope;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.38px;
          text-align: center;
        }
       
    }
    .sidenav.sidenav-absolute {
      position: fixed !important;
      height: 100% !important;
      top: 0 !important;
      width: 100% !important;
      background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%) !important;
    }
    .sidenav-backdrop {
      visibility: hidden;
    }
    .sidenav-menu {
      height: 100%;
    }
    .side-menu-list{
      height: 90%;
      align-items: center;
      display: flex;
      .sidenav-item {
          height: 80px;
          display: flex;
          justify-content: center;

          .sidenav-link {
            color: #FFF;
            //font-family: Manrope;
            font-size: 20px;
            font-style: normal;
            line-height: 150%;
            letter-spacing: -0.32px; 
            position: relative;
            width: auto;
            //margin: 0 30px;
            padding: 0 50px;
            opacity: 0.8;
            justify-content: center;
            span {
                font-weight: 500!important;
                letter-spacing: 1.6px!important;
            
            }
    
            &:hover {
                opacity: 1;
                color: #ffffff !important;
                font-weight: 700!important;
            }
    
            &.active {
                opacity: 1;
                color: #ffffff !important;
                font-weight: 700!important;
                &::after {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: 15px;
                    right: 0;
                    
                    height: 17px;
                    width: 34px;
                    border-radius: 12px;
                    background-color: #FFC233;
                }
            }
          }
        }
    }
    .language-menu-list {
      .sidenav-item {
          height: 60px;
          .sidenav-link {
            color: #FFF;
            //font-family: Manrope;
            font-size: 20px;
            font-style: normal;
            //font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.32px; 
            position: relative;
            width: 121px;
            margin-right: 30px;
            padding: 0;
            opacity: 0.8;
            &.en-item {
                width: 40px;
                margin-right: 0 !important;
                height: 60px;
                &::after {
                    background: #ffffff !important;
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    width: 2px;
                    height: 20px;
                    top: 21px;
                    right: 6px;
                    opacity: 1;
                }
                &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 0 !important;
                    border-radius: 12px;
                    background-color: #FFC233;
                }
            }
            &.de-item {
                width: 40px;
                margin-right: 0;
                height: 60px;
                &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 0 !important;
                    border-radius: 12px;
                    background-color: #FFC233;
                }
            }
            span {
                font-weight: 500!important;
                letter-spacing: 1.6px!important;
            
            }
    
            &:hover {
                opacity: 1;
                color: #ffffff !important;
                font-weight: 700!important;
            }
    
            &.active {
                opacity: 1;
                color: #ffffff !important;
                font-weight: 700!important;
                &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 23.38px;
                    border-radius: 12px;
                    background-color: #FFC233;
                }
            }
          }
        }
    }
      
   
}


  