.team-div{
    width: 100%;
    min-height: 100vh;
	background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
	position: relative;
    height: 100%;
    .teampage-row {
        padding-top: 50px;
        .top-col-left {
            position: relative;
            z-index: 1040;
            .top-text {
                max-width: 522px;
                color: #ffffff;
                font-size: 48px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 66px;
            }
            .middle-text {
                color: #ffffff;
                height: 100px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.42px;
                line-height: 22px;
            }
            .team-1 {
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                margin-bottom: 35px;
                visibility: visible;
                max-width: 300px;
                .details {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: calc(100% - 5px);
                    text-align: left;
                    font-size: 13px;
                    line-height: 20px;
                    position: absolute;
                    padding: 15px;
                    top: -140px;
                    left: 0;
                    width: 100%;
                    transition: all 1500ms;
                    opacity: 0;
                    &:hover{
                        top: 0;
                        border-radius: 15px;
                        background-color: #FFFFFF;
                        color: #202249;
                        opacity: 1;
                    }
                }
                .profile-image {
                    border-radius: 0;
                    height: 100%;
                    width: 100%;
                    margin: auto;
                    overflow: hidden;
                    margin-bottom: 25px;
                    border-radius: 50%;
                    vertical-align: middle;
                }
                .member-details {
                    position: relative;
                    //display: inline-block;
                    padding-bottom: 5px;
                    
                    .h5 {
                        //text-transform: uppercase;
                        color: #FFFFFF;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0.36px;
                        line-height: 30px;
                        text-align: center;
                        position: relative;
                        justify-content: center;
                        display: flex;
                    }
                    .dark-border-bottom::before {
                        position: absolute;
                        margin: auto;
                        z-index: 1;
                        content: "";
                        width: 35%;
                        height: 2px;
                        background: #FFFFFF;
                        background-color: rgb(251, 251, 251);
                        bottom: -8px;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                    .position {
                        height: 35px;
                        font-size: 13px;
                        margin-top: 15px;
                        justify-content: center;
                        display: flex;
                        line-height: 17px;
                        span {
                            font-size: 13px;
                            text-align: center;
                            max-width: 180px;
                            color: #FFFFFF;
                        }
                    }
    
                }
            }
            .team-2 {
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                margin-bottom: 35px;
                visibility: visible;
                max-width: 300px;
                .details {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: calc(100% - 5px);
                    text-align: left;
                    font-size: 13px;
                    line-height: 20px;
                    position: absolute;
                    padding: 15px;
                    top: -140px;
                    left: 0;
                    width: 100%;
                    transition: all 1500ms;
                    opacity: 0;
                    &:hover{
                        top: 0;
                        border-radius: 15px;
                        background-color: #FFFFFF;
                        color: #202249;
                        opacity: 1;
                    }
                }
                .profile-image {
                    border-radius: 0;
                    height: 100%;
                    width: 100%;
                    margin: auto;
                    overflow: hidden;
                    margin-bottom: 25px;
                    border-radius: 50%;
                    vertical-align: middle;
                }
                .member-details {
                    position: relative;
                    //display: inline-block;
                    padding-bottom: 5px;
                    
                    .h5 {
                        //text-transform: uppercase;
                        color: #FFFFFF;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0.36px;
                        line-height: 30px;
                        text-align: center;
                        position: relative;
                        justify-content: center;
                        display: flex;
                    }
                    .dark-border-bottom::before {
                        position: absolute;
                        margin: auto;
                        z-index: 1;
                        content: "";
                        width: 35%;
                        height: 2px;
                        background: #FFFFFF;
                        background-color: rgb(251, 251, 251);
                        bottom: -8px;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                    .position {
                        height: 35px;
                        font-size: 13px;
                        margin-top: 15px;
                        justify-content: center;
                        display: flex;
                        line-height: 17px;
                        span {
                            font-size: 13px;
                            text-align: center;
                            max-width: 180px;
                            color: #FFFFFF;
                        }
                    }
                    .position-distri {
                        //height: 35px;
                        font-size: 13px;
                        margin-top: 15px;
                        justify-content: center;
                        display: flex;
                        line-height: 17px;
                        span {
                            font-size: 13px;
                            text-align: center;
                            max-width: 180px;
                            color: #FFFFFF;
                        }
                    }
    
                }
            }
        }
        .interb2b-logo-col {
            position: relative;
            z-index: 1040;
            .design-element-1 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                margin-left: -141px;
                margin-top: -172px;
                height: 95px;
            }
            .big-logo-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                height: 425.56px;
                width: 468px;
                box-shadow: 0 12px 20px 0 rgba(255,255,255,0.04);
            }
            .orange-circle-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                margin-top: 275px;
                margin-left: 130px;
            }
            .orange-circle-div {
                height: 56px;
                width: 56px;
                border-radius: 50%;
                margin-left: 30px;
                background-color: #EE6C4D;
                box-shadow: 0 0 99px 0 rgba(0,0,0,0.16);
            }
            .description-text {
                height: 56px;
                width: 250px;
                transform: rotate(360deg);
                color: #FFFFFF;
                font-size: 18px;
                font-weight: 800;
                letter-spacing: 0.36px;
                //line-height: 56px;
                text-align: right;
            }
            .cover-shadow-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                margin-top:  300px;
            }
        }
    }
    
   
    

    @media (min-width: 992px) and (max-width: 1520px){
        .teampage-row {
            padding-top: 0;
            .top-col-left {
                .top-text {
                    font-size: 38px;
                    line-height: 38px;
                }
                .middle-text {
                    height: 68px;
                    font-size: 16px;
                    line-height: 22px;
                }
                .team-1 {
                    margin-bottom: 15px;
                    .profile-image {
                        margin-bottom: 10px;
                    }
                }
                .team-2 {
                    margin-bottom: 0;
                    .profile-image {
                        margin-bottom: 10px;
                    }
                }
            }
            .interb2b-logo-col {
                .design-element-1 {
                    margin-left: 0;
                    margin-top: -155px;
                    height: 95px;
                }
            }
        }
    }
    @media (max-width: 992px){
        .teampage-row {
            padding-top: 25px;
            .top-col-left {
                .top-text {
                    font-size: 42px;
                    line-height: 50px;
                }
                .middle-text {
                    height: 70px;
                    font-size: 16px;
                    line-height: 22px;
                }
                .team-1 {
                    margin-bottom: 15px;
                    .profile-image {
                        margin-bottom: 10px;
                    }
                }
                .team-2 {
                    margin-bottom: 0;
                    .profile-image {
                        margin-bottom: 10px;
                    }
                }
            }
            .interb2b-logo-col {
                .design-element-1 {
                    margin-left: 0;
                    margin-top: -155px;
                    height: 95px;
                }
            }
        }
    }
    
}