
.bodywrapper {
	min-height: 100vh;
	width: 100%;
	padding-top: 85px;
	background-color: transparent;
	//background-image: url('../../media/images/Background - Orange.svg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	position: relative;
	overflow: hidden;
}





.logo {
	width: 112px;
	height: 101px;

}

@media (min-width: 410px) and (max-width: 575.98px) {

}

@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
}
