@keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

.first-section{
    width: 100%;
	background-color: transparent;
	position: relative;
    height: 1100px;
    overflow: hidden;
    .blue-background {
        height: auto;
        width: 100%;
        position: absolute;
        z-index: 1020;
    }
    
    .top-col-left {
        position: relative;
        z-index: 1040;
    }
    
    .top-col-right {
        position: relative;
        z-index: 1040;
        button {
            margin: 0;
            padding: 15px 30px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
    
    .middle-col-left {
        z-index: 1040;
        .title-text {
            color: #FFFFFF;
            //font-family: Manrope;
            font-size: 64px;
            font-weight: 600;
            letter-spacing: -0.83px;
            line-height: 72px;
        }
        .description-text {
            color: #FFFFFF;
            //font-family: Manrope;
            font-size: 18px;
            letter-spacing: 0.36px;
            line-height: 32px;
        }
        button {
            width: auto;
            margin: 0;
            padding: 18px 50px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
    
    .interb2b-logo-col {
        position: relative;
        z-index: 1040;
        .design-element-1 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-left: -141px;
            margin-top: -172px;
            height: 95px;
        }
        .design-element-1-tech {
            position: absolute;
            //top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-left: -129px;
            margin-top: -145px;
            height: 95px;
            z-index: -1;
        }
        .big-logo-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 425.56px;
            width: 468px;
            box-shadow: none;;
        }
        .orange-circle-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-top: 275px;
            margin-left: 130px;
        }
        .orange-circle-div {
            height: 56px;
            width: 56px;
            border-radius: 50%;
            margin-left: 30px;
            background-color: #EE6C4D;
            box-shadow: 0 0 99px 0 rgba(0,0,0,0.16);
        }
        .description-text {
            height: 56px;
            width: 250px;
            transform: rotate(360deg);
            color: #FFFFFF;
            font-size: 18px;
            font-weight: 800;
            letter-spacing: 0.36px;
            //line-height: 56px;
            text-align: right;
        }
        .cover-shadow-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-top:  300px;
        }
    }

    @media (min-width: 992px) and (max-width: 1520px){
        .blue-background {
            width: 100%;
            position: absolute;
            z-index: 1020;
            height: 800px;
        }
        .middle-col-left {
            z-index: 1040;
            .title-text {
                font-size: 26px;
                line-height: 34px;
            }
        }
        
        .interb2b-logo-col {
            .design-element-1 {
                margin-left: -100px;
                margin-top: -115px;
                height: 70px;
            }
            .big-logo-img {
                height: 285px;
                box-shadow: 0 12px 20px 0 rgba(255,255,255,0.04);
            }
            .orange-circle-img {
                margin-top: 160px;
                margin-left: 85px;
            }
            .cover-shadow-img {
                margin-top: 190px;
                width: 350px;
            }
        }
    }
    @media (max-width: 991.9px){
        height: 100%;
        .blue-background {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1020;
        }
        .middle-col-left {
            z-index: 1040;
            display: grid;
            justify-content: center;
            //max-height: 500px;
            position: relative;
            .respo-design-element_1 {
                position: absolute;
                width: 122px;
                top: 47%;
                right: 50%;
                transform: translate(138%, -143%);
            }
            .respo-logo {
                width: auto;
                height: 94px;
                margin-bottom: 15px;
            }
            .title-text-first {
                width: 100%;
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 36px;
                font-weight: 600;
                letter-spacing: -0.59px;
                line-height: 50px;
            }
            .title-text {
                width: 100%;
                max-width: 350px;
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 36px;
                font-weight: 600;
                letter-spacing: -0.59px;
                line-height: 50px;
            }
            .description-text {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 18px;
                letter-spacing: 0.36px;
                line-height: 32px;
                text-align: justify;
            }
            button {
                width: 100%;
                max-width: 500px;
                max-height: 60px;
            }
        }
        
        .interb2b-logo-col {
            .design-element-1 {
                margin-left: -100px;
                margin-top: -115px;
                height: 70px;
            }
            .big-logo-img {
                height: 285px;
                box-shadow: 0 12px 20px 0 rgba(255,255,255,0.04);
            }
            .orange-circle-img {
                margin-top: 160px;
                margin-left: 85px;
            }
            .cover-shadow-img {
                margin-top: 190px;
                width: 350px;
            }
        }
    }
}

.second-section {
    height: 850px;
    padding: 90px 60px 50px 60px;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    .deisgn-element-2 {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-15%, -24%);
        height: 364px;
    }
    
    .first-card {
    
        position: relative;
        .design-element-3 {
            position: absolute;
            top: 20px;
            left: -30px;
            height: 31px;
        }
        .design-element-10 {
            position: absolute;
            bottom: -51px;
            right: -71px;
        }
    }
    .third-container {
        position: relative;
    }
           
    .top-text {
        //height: 44px;
        width: 153px;
        //color: #202249;
        background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        //font-family: Manrope;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.42px;
        line-height: 22px;
        //display: inline-flex;
        
    }
    .middle-text {
        position: relative;
        //height: 66px;
        width: 522px;
        color: #202249;
        //font-family: Manrope;
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 66px;
        @media (max-width: 992px){
            width: 100%;
            line-height: 45px;
            font-size: 44px;
        }
        //display: inline-flex;
        img {
            position: absolute;
            top: -110px;
            right: 10px;
            height: 86px;
        }
    }
    .bottom-text {
        //height: 210px;
        width: 472px;
        color: #202249;
        //font-family: Manrope;
        font-size: 18px;
        letter-spacing: 0.36px;
        line-height: 32px;
        //display: inline-flex;
    }

    @media (min-width: 992px) and (max-width: 1520px){
        .deisgn-element-2 {
            height: 295px;
        }
        .first-card {
           .design-element-3 {
                top: 20px;
                left: -30px;
                height: 31px;
            }
            .design-element-9 {
                width: 400px;
            }
            .design-element-10 {
                bottom: -42px;
                right: -50px;
                width: 265px;
            }
        }
       
        .middle-text {
            width: 380px;
            font-size: 36px;
            line-height: 55px;
            img {
                top: -100px;
                right: -6px;
                height: 77px;
            }
        }
        .bottom-text {
            width: 380px;
            font-size: 14px;
            line-height: 28px;
        }
    }
    @media (max-width: 991.9px){
        height: unset;
        padding: 120px 0 0 0;
        position: relative;
        overflow: hidden;
        background-color: #E8ECEE;
        margin-top: -80px;
        .deisgn-element-2 {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-15%, -24%);
            height: 364px;
        }
        .tech-title-text {
            display: flex;
            justify-content: center;
            padding: 0 15px 50px 15px;
            span {
                max-width: 500px;
                line-height: 35px;
                color: #202249;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                text-align: center;
            }
        }
        .first-card {
        
            position: relative;
            .design-element-3 {
                position: absolute;
                top: 20px;
                left: -30px;
                height: 31px;
            }
            .design-element-10 {
                position: absolute;
                bottom: -51px;
                right: -71px;
            }
        }
        .third-container {
            position: relative;
        }
        .top-text {
            width: 100%;
            font-size: 18px;
        }
        .bottom-text {
            max-width: 472px;
            width: 100%;
            font-size: 16px;
            line-height: 25px;
            text-align: justify;
        }
        .tech-footer-contact-text {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            span {
                background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
                background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.42px;
                line-height: 22px;
            }
            
        }
        .tech-footer-button {
            display: flex;
            justify-content: center;
            button {
                width: auto;
               // margin-left: 50px;
                padding: 18px 50px;
                background-color: #FF6701;
                box-shadow: none !important;
                border-radius: 30px;
                cursor: pointer;
                border: none;
                span {
                    color: #FFFFFF;
                    //font-family: Manrope;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.48px;
                    line-height: 17px;
                    text-align: center;
                }
            }
        }
        .design-col {
            position: relative;
            padding-top: 30px;
            .first-card {
                position: relative;
                .design-element-9 {
                    width: 95%;
                    margin-bottom: 50px !important;
                    margin-left: -15px;
                }
                
                .design-element-3 {
                    position: absolute;
                    top: 20px;
                    left: -36px;
                    height: 25px;
                }
                .design-element-10 {
                    position: absolute;
                    width: 63%;;
                    top: 57%;
                    right: 0;
                    transform: translate(0, -50%);
                }
            }
        }
    }

}

.second-section-spec {
    padding: 340px 60px 60px 60px;
    position: relative;
    overflow: hidden;
    background-color: #E8ECEE;
    margin-top: -316px;
    .deisgn-element-2 {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-15%, -24%);
        height: 364px;
    }
    
    .first-card {
    
        position: relative;
        .design-element-3 {
            position: absolute;
            top: 20px;
            left: -30px;
            height: 31px;
        }
        .design-element-10 {
            position: absolute;
            bottom: -51px;
            right: -71px;
        }
        .design-element-9 {
            margin-top: 0;
        }
    }
    .third-container {
        position: relative;
    }
    .tech-title-text {
        display: flex;
        justify-content: center;
        padding-bottom: 50px;
        span {
            max-width: 750px;
            line-height: 50px;
            color: #202249;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0;
            text-align: center;
        }
        
    }
    .tech-footer-text {
        display: flex;
        justify-content: center;
        padding-top: 70px;
        span {
            max-width: 950px;
            line-height: 26px;
            color: #202249;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
        }
        
    }
    .tech-footer-text-private {
        padding-top: 70px;
        span {
            //max-width: 950px;
            line-height: 26px;
            color: #202249;
            font-size: 18px;
            letter-spacing: 0;
            text-align: centlaefter;
        }
        
    }
    .tech-footer-contact-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        span {
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.42px;
            line-height: 22px;
        }
        
    }
    .tech-footer-button {
        display: flex;
        justify-content: center;
        button {
            width: auto;
           // margin-left: 50px;
            padding: 18px 50px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }       
    .top-text {
        //height: 44px;
        //width: 153px;
        //color: #202249;
        background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        //font-family: Manrope;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.42px;
        line-height: 22px;
        //display: inline-flex;
        
    }
    .middle-text {
        position: relative;
        //height: 66px;
        width: 522px;
        color: #202249;
        //font-family: Manrope;
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 66px;
        //display: inline-flex;
        img {
            position: absolute;
            top: -110px;
            right: 10px;
            height: 86px;
        }
    }
    .bottom-text {
        //height: 210px;
        max-width: 621px;
        color: #202249;
        //font-family: Manrope;
        font-size: 18px;
        letter-spacing: 0.36px;
        line-height: 32px;
        //display: inline-flex;
    }

    @media (min-width: 992px) and (max-width: 1520px){
        .deisgn-element-2 {
            height: 295px;
        }
        .first-card {
           .design-element-3 {
                top: 20px;
                left: -30px;
                height: 31px;
            }
            .design-element-9 {
                width: 400px;
            }
            .design-element-10 {
                bottom: -42px;
                right: -50px;
                width: 265px;
            }
        }
       
        .middle-text {
            width: 380px;
            font-size: 36px;
            line-height: 55px;
            img {
                top: -100px;
                right: -6px;
                height: 77px;
            }
        }
        .bottom-text {
            width: 380px;
            font-size: 14px;
            line-height: 28px;
        }
    }
    @media (max-width: 991.9px){
        height: 850px;
        padding: 90px 10px 0 10px;
        position: relative;
        overflow: hidden;
        margin-top: -90px;
        .design-col {
            position: relative;
            padding-top: 30px;
            .deisgn-element-2 {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-42%, 50%);
                width: 100%;
                height: unset;
            }
            .first-card {
                .design-element-9 {
                    width: 95%;
                    margin-bottom: 50px !important;
                    margin-left: -15px;
                }
                
                .design-element-3 {
                    position: absolute;
                    top: 20px;
                    left: -36px;
                    height: 25px;
                }
                .design-element-10 {
                    width: 63%;
                    position: relative;
                    top: 0;
                    right: 0;
                    transform: translate(65%, -102%);
                }
            }
        }
       
       
        .top-text {
            //height: 44px;
            width: 153px;
            //color: #202249;
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            //font-family: Manrope;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
            //display: inline-flex;
            
        }
        .middle-text {
            position: relative;
            //height: 66px;
            width:100%;
            color: #202249;
            //font-family: Manrope;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 40px;
            //display: inline-flex;
            img {
                position: absolute;
                top: -110px;
                right: 10px;
                height: 86px;
            }
        }
        .bottom-text {
            //height: 210px;
            width: 100%;
            color: #202249;
            //font-family: Manrope;
            font-size: 16px;
            letter-spacing: 0.36px;
            line-height: 30px;
            //display: inline-flex;
        }
    }

}

.third-section-spec {
    //height: 1040px;
    padding: 310px 60px 60px 60px;
    position: relative;
    overflow: hidden;
    background-color: #E8ECEE;
    margin-top: -250px;
    .third-container {
        position: relative;
        .deisgn-element-5 {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(35%, -19%);
            height: 600px;
        }
        .stack-col-right {
            position: relative;
            //max-width: 720px;
            z-index: 1020;
            .design-element-3 {
                position: absolute;
                top: 115px;
                left: 130px;
                height: 31px;
            }
            .design-element-4 {
                position: absolute;
                bottom: -30px;
                left: 745px;
                transform: translate(0, 0);
                z-index: 1000;
                height: 86px;
            }
            .technologie-1 {
                display: flex;
                align-items: center;
                justify-content: end;
                margin-bottom: 28.17px;
                .javascript {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    margin-right: 45px;
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
                .kotlin {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    margin-right: 45px;
                    img {
                        width: 120px;
                    }
                }
                .unity {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        width: 120px;
                    }
                }
            }
            .technologie-2 {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 28.17px;
                .springboot {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    margin-right: 45px;
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
                .reactjs {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
            }
            .technologie-3 {
                display: flex;
                align-items: center;
                justify-content: start;
                margin-bottom: 28.17px;
                margin-left: 108.75px;
                .java {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    margin-right: 45px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
                .neo4j {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
            }
            .technologie-4 {
                display: flex;
                align-items: center;
                justify-content: end;
                margin-bottom: 28.17px;
                position: relative;
                z-index: 1020;
                margin-right: 108.75px;
                .lottiefiles {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    margin-right: 45px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                }
                .invision {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                }
            }
            .technologie-5 {
                display: flex;
                align-items: center;
                justify-content: start;
                
                .sketch {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    margin-right: 45px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                }
                .figma {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    margin-right: 45px;
                }
                .swift {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        width: 120px;
                    }
                }
            }
        }
        .top-text {
            //height: 44px;
            width: 153px;
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            //font-family: Manrope;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
            //display: inline-flex;
        }
        .middle-text {
            //height: 66px;
            width: 522px;
            color: #202249;
            //font-family: Manrope;
            font-size: 48px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 66px;
            //display: inline-flex;
        }
        .middle-text-top {
            //height: 66px;
            //width: 522px;
            color: #202249;
            //font-family: Manrope;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 66px;
            display: flex;
            justify-content: center;
        }
        .bottom-text {
            //height: 210px;
            width: 472px;
            color: #202249;
            //font-family: Manrope;
            font-size: 18px;
            letter-spacing: 0.36px;
            line-height: 32px;
            //display: inline-flex;
        }
        .bottom-text-tech {
            display: flex;
            justify-content: center;
            color: #202249;
            font-size: 18px;
            letter-spacing: 0.36px;
            line-height: 32px;
            
            p {
                font-weight: 600;
                margin-bottom: 5px !important;
            }
        }
    }
    .tech-title-text {
        display: flex;
        justify-content: center;
        padding-bottom: 50px;
        span {
            max-width: 950px;
            line-height: 50px;
            color: #202249;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0;
            text-align: center;
        }
        
    }
    .tech-footer-text {
        display: flex;
        justify-content: center;
        padding-top: 70px;
        span {
            max-width: 950px;
            line-height: 66px;
            color: #202249;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0;
            text-align: center;
        }
        
    }
    .tech-footer-contact-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        span {
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
        }
        
    }
    .tech-footer-button {
        display: flex;
        justify-content: center;
        button {
            width: auto;
            margin: 0;
            padding: 18px 50px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
    .deisgn-element-5 {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(35%, -38%);
        height: 600px;
    }
    .stack-col-right {
        position: relative;
        //max-width: 720px;
        z-index: 1020;
        .design-element-3 {
            position: absolute;
            top: 115px;
            left: 130px;
            height: 31px;
        }
        .design-element-4 {
            position: absolute;
            bottom: -90px;
            left: 745px;
            transform: translate(0, 0);
            z-index: 1000;
            height: 86px;
        }
        .technologie-1 {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-bottom: 28.17px;
            .javascript {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                margin-right: 45px;
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .kotlin {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                margin-right: 45px;
                img {
                    width: 120px;
                }
            }
            .unity {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    width: 120px;
                }
            }
        }
        .technologie-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 28.17px;
            .springboot {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                margin-right: 45px;
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .reactjs {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
        }
        .technologie-3 {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-bottom: 28.17px;
            margin-left: 108.75px;
            .java {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                margin-right: 45px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .neo4j {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
        }
        .technologie-4 {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-bottom: 28.17px;
            position: relative;
            z-index: 1020;
            margin-right: 108.75px;
            .lottiefiles {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                margin-right: 45px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
            .invision {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
        .technologie-5 {
            display: flex;
            align-items: center;
            justify-content: start;
            
            .sketch {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                margin-right: 45px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
            .figma {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                margin-right: 45px;
            }
            .swift {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    width: 120px;
                }
            }
        }
    }
    .top-text {
        //height: 44px;
        width: 153px;
        background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        //font-family: Manrope;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.42px;
        line-height: 22px;
        //display: inline-flex;
    }
    .middle-text {
        //height: 66px;
        width: 522px;
        color: #202249;
        //font-family: Manrope;
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 66px;
        //display: inline-flex;
    }
    .bottom-text {
        //height: 210px;
        width: 472px;
        color: #202249;
        //font-family: Manrope;
        font-size: 18px;
        letter-spacing: 0.36px;
        line-height: 32px;
        //display: inline-flex;
    }
    @media (min-width: 992px) and (max-width: 1520px){
        padding: 60px 20px;
        .third-container {
            .middle-text {
                width: 380px;
                font-size: 36px;
                line-height: 55px;
                img {
                    top: -100px;
                    right: -6px;
                    height: 77px;
                }
            }
            .bottom-text {
                width: 380px;
                font-size: 14px;
                line-height: 28px;
            }
            .deisgn-element-5 {
                transform: translate(25%, -60%);
                height: 430px;
            }
            .stack-col-right {
                position: relative;
                //max-width: 720px;
                z-index: 1020;
                .design-element-3 {
                    position: absolute;
                    top: 80px;
                    left: 50px;
                    height: 31px;
                }
                .design-element-4 {
                    position: absolute;
                    bottom: -18px;
                    left: 520px;
                    transform: translate(0, 0);
                    z-index: 1000;
                    height: 65px;
                }
                .technologie-1 {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    margin-bottom: 15px;
                    .javascript {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .kotlin {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            width: 75px;
                        }
                    }
                    .unity {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            width: 75px;
                        }
                    }
                }
                .technologie-2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 28.17px;
                    .springboot {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .reactjs {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                }
                .technologie-3 {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    margin-bottom: 28.17px;
                    margin-left: 65px;
                    .java {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .neo4j {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                }
                .technologie-4 {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    margin-bottom: 28.17px;
                    position: relative;
                    z-index: 1020;
                    margin-right: 60px;
                    .lottiefiles {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            width: 105px;
                        }
                    }
                    .invision {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            width: 130px;
                        }
                    }
                }
                .technologie-5 {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    
                    .sketch {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            width: 75px;
                        }
                    }
                    .figma {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            width: 75px;
                        }
                    }
                    .swift {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            width: 75px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 991.9px){
        height: unset;
        padding: 0 10px 0 10px;
        position: relative;
        overflow: hidden;
        margin-top: -160px;
        
        .stack-col-right {
            position: relative;
            //height: 270px;
            max-width: 992px;
            padding-top: 85px;
            z-index: 1020;
            display: flex;
            .deisgn-element-5 {
                margin-right: -100px;
                position: unset;
                transform: unset;
                height: 317.92px;
                width: 341.17px;
            }
            .design-element-3 {
                position: absolute;
                top: 25px;
                left: -70px;
                height: 31px;
            }
            .design-element-4 {
                position: absolute;
                bottom: 45px;
                right: 125px;
                transform: unset;
                z-index: 0;
                height: 66px;
            }
           
           
        }
        .carousal-element {
            //position: absolute;
            height: 270px;
            margin-top: -355px;
            z-index: 2000;
            
            //width: 700px;
                .technologie-1 {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    margin-bottom: 28.17px;
                    animation: slide infinite 5s linear;
                    //margin-left: -966px;
                    width: 100px;
                    .javascript {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        padding: 0 20px;
                        width: unset;
                        border-radius: 13.79px;
                        margin-right: 30px;
                        margin-left: 200px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            height: 37.24px;
                            width: 37.24px;
                        }
                        span {
                            color: #202249;
                            //font-family: Manrope;
                            font-size: 18px;
                            font-weight: 800;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .invision {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        padding: 0 20px;
                        width: unset;
                        border-radius: 13.79px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            height: 95px;
                        }
                    }
                    .sketch {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        padding: 0 20px;
                        width: unset;
                        margin-right: 30px;
                        border-radius: 13.79px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            height: 24.16px;
                            width: 106.2px;
                        }
                    }
                }
                .technologie-2 {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    margin-bottom: 28.17px;
                    .springboot {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        padding: 0 20px;
                        width: unset;
                        border-radius: 13.79px;
                        margin-right: 30px;
                        margin-left: 100px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            height: 37.2px;
                            width: 37.24px;
                        }
                        span {
                            color: #202249;
                            //font-family: Manrope;
                            font-size: 18px;
                            font-weight: 800;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .reactjs {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        padding: 0 20px;
                        width: unset;
                        border-radius: 13.79px;
                        margin-right: 30px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            height: 36.44px;
                            width: 34.48px;
                        }
                        span {
                            color: #202249;
                            //font-family: Manrope;
                            font-size: 18px;
                            font-weight: 800;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .figma {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        padding: 0 20px;
                        width: unset;
                        border-radius: 13.79px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            width: 95px;
                        }
                    }
                }
                .technologie-3 {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    margin-bottom: 28.17px;
                    .java {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        padding: 0 20px;
                        width: unset;
                        margin-right: 30px;
                        border-radius: 13.79px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            height: 37.24px;
                            width: 37.24px;
                        }
                        span {
                            color: #202249;
                            //font-family: Manrope;
                            font-size: 18px;
                            font-weight: 800;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .neo4j {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55px;
                        padding: 0 20px;
                        width: unset;
                        border-radius: 13.79px;
                        margin-right: 30px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            height: 42.19px;
                            width: 37.24px;
                        }
                        span {
                            color: #202249;
                            //font-family: Manrope;
                            font-size: 18px;
                            font-weight: 800;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .lottiefiles {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 55.86px;
                        padding: 0 20px;
                        width: unset;
                        border-radius: 13.79px;
                        background-color: #FFFFFF;
                        box-shadow: 0 10px 14px 6px rgba(0,0,0,0.05);
                        margin-right: 30px;
                        img {
                            width: 100px
                        }
                    }
                }
           
        }
        
        .top-text {
            //height: 44px;
            width: 153px;
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            //font-family: Manrope;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
            //display: inline-flex;
        }
        .middle-text {
            //height: 66px;
            width: 100%;
            color: #202249;
            //font-family: Manrope;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 40px;
            //display: inline-flex;
        }
        .bottom-text {
            //height: 210px;
            width: 100%;
            color: #202249;
            //font-family: Manrope;
            font-size: 16px;
            letter-spacing: 0.36px;
            line-height: 30px;
            //display: inline-flex;
        }
    }

}

.third-section {
    //height: 1040px;
    height: 910px;
    padding: 90px 60px 50px 60px;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    .third-container {
        position: relative;
        .deisgn-element-5 {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(35%, -19%);
            height: 600px;
        }
        .stack-col-right {
            position: relative;
            //max-width: 720px;
            z-index: 1020;
            .design-element-3 {
                position: absolute;
                top: 115px;
                left: 130px;
                height: 31px;
            }
            .design-element-4 {
                position: absolute;
                bottom: -30px;
                left: 745px;
                transform: translate(0, 0);
                z-index: 1000;
                height: 86px;
            }
            .technologie-1 {
                display: flex;
                align-items: center;
                justify-content: end;
                margin-bottom: 28.17px;
                .javascript {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    margin-right: 45px;
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
                .kotlin {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    margin-right: 45px;
                    img {
                        width: 120px;
                    }
                }
                .unity {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        width: 120px;
                    }
                }
            }
            .technologie-2 {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 28.17px;
                .springboot {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    margin-right: 45px;
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
                .reactjs {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
            }
            .technologie-3 {
                display: flex;
                align-items: center;
                justify-content: start;
                margin-bottom: 28.17px;
                margin-left: 108.75px;
                .java {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    margin-right: 45px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
                .neo4j {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        margin-right: 15px;
                        width: 40px;
                    }
                    span {
                        color: #202249;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 0.62px;
                        line-height: 22px;
                    }
                }
            }
            .technologie-4 {
                display: flex;
                align-items: center;
                justify-content: end;
                margin-bottom: 28.17px;
                position: relative;
                z-index: 1020;
                margin-right: 108.75px;
                .lottiefiles {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    margin-right: 45px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                }
                .invision {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                }
            }
            .technologie-5 {
                display: flex;
                align-items: center;
                justify-content: start;
                
                .sketch {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    margin-right: 45px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                }
                .figma {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    margin-right: 45px;
                }
                .swift {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 210px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    img {
                        width: 120px;
                    }
                }
            }
        }
        .top-text {
            //height: 44px;
            width: 153px;
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            //font-family: Manrope;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
            //display: inline-flex;
        }
        .middle-text {
            //height: 66px;
            width: 522px;
            color: #202249;
            //font-family: Manrope;
            font-size: 48px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 66px;
            //display: inline-flex;
        }
        .middle-text-top {
            //height: 66px;
            //width: 522px;
            color: #202249;
            //font-family: Manrope;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 66px;
            display: flex;
            justify-content: center;
        }
        .bottom-text {
            //height: 210px;
            width: 472px;
            color: #202249;
            //font-family: Manrope;
            font-size: 18px;
            letter-spacing: 0.36px;
            line-height: 32px;
            //display: inline-flex;
        }
        .bottom-text-tech {
            display: flex;
            justify-content: center;
            color: #202249;
            font-size: 18px;
            letter-spacing: 0.36px;
            line-height: 32px;
            
            p {
                font-weight: 600;
                margin-bottom: 5px !important;
            }
        }
    }
    .tech-title-text {
        display: flex;
        justify-content: center;
        padding-bottom: 50px;
        span {
            max-width: 950px;
            line-height: 50px;
            color: #202249;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0;
            text-align: center;
        }
        
    }
    .tech-footer-text {
        display: flex;
        justify-content: center;
        padding-top: 70px;
        span {
            max-width: 950px;
            line-height: 66px;
            color: #202249;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0;
            text-align: center;
        }
        
    }
    .tech-footer-contact-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        span {
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
        }
        
    }
    .tech-footer-button {
        display: flex;
        justify-content: center;
        button {
            width: auto;
            margin: 0;
            padding: 18px 50px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
    .deisgn-element-5 {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(35%, -38%);
        height: 600px;
    }
    .stack-col-right {
        position: relative;
        //max-width: 720px;
        z-index: 1020;
        .design-element-3 {
            position: absolute;
            top: 115px;
            left: 130px;
            height: 31px;
        }
        .design-element-4 {
            position: absolute;
            bottom: -90px;
            left: 745px;
            transform: translate(0, 0);
            z-index: 1000;
            height: 86px;
        }
        .technologie-1 {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-bottom: 28.17px;
            .javascript {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                margin-right: 45px;
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .kotlin {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                margin-right: 45px;
                img {
                    width: 120px;
                }
            }
            .unity {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    width: 120px;
                }
            }
        }
        .technologie-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 28.17px;
            .springboot {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                margin-right: 45px;
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .reactjs {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
        }
        .technologie-3 {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-bottom: 28.17px;
            margin-left: 108.75px;
            .java {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                margin-right: 45px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .neo4j {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    margin-right: 15px;
                    width: 40px;
                }
                span {
                    color: #202249;
                    font-size: 24px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
        }
        .technologie-4 {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-bottom: 28.17px;
            position: relative;
            z-index: 1020;
            margin-right: 108.75px;
            .lottiefiles {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                margin-right: 45px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
            .invision {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
        .technologie-5 {
            display: flex;
            align-items: center;
            justify-content: start;
            
            .sketch {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                margin-right: 45px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
            .figma {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                margin-right: 45px;
            }
            .swift {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 210px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                img {
                    width: 120px;
                }
            }
        }
    }
    .top-text {
        //height: 44px;
        width: 153px;
        background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        //font-family: Manrope;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.42px;
        line-height: 22px;
        //display: inline-flex;
    }
    .middle-text {
        //height: 66px;
        width: 522px;
        color: #202249;
        //font-family: Manrope;
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 66px;
        //display: inline-flex;
    }
    .bottom-text {
        //height: 210px;
        width: 472px;
        color: #202249;
        //font-family: Manrope;
        font-size: 18px;
        letter-spacing: 0.36px;
        line-height: 32px;
        //display: inline-flex;
    }
    @media (min-width: 992px) and (max-width: 1520px){
        padding: 60px 20px;
        .third-container {
            .middle-text {
                width: 380px;
                font-size: 36px;
                line-height: 55px;
                img {
                    top: -100px;
                    right: -6px;
                    height: 77px;
                }
            }
            .bottom-text {
                width: 380px;
                font-size: 14px;
                line-height: 28px;
            }
            .deisgn-element-5 {
                transform: translate(25%, -60%);
                height: 430px;
            }
            .stack-col-right {
                position: relative;
                //max-width: 720px;
                z-index: 1020;
                .design-element-3 {
                    position: absolute;
                    top: 80px;
                    left: 50px;
                    height: 31px;
                }
                .design-element-4 {
                    position: absolute;
                    bottom: -18px;
                    left: 520px;
                    transform: translate(0, 0);
                    z-index: 1000;
                    height: 65px;
                }
                .technologie-1 {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    margin-bottom: 15px;
                    .javascript {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .kotlin {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            width: 75px;
                        }
                    }
                    .unity {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            width: 75px;
                        }
                    }
                }
                .technologie-2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 28.17px;
                    .springboot {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .reactjs {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                }
                .technologie-3 {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    margin-bottom: 28.17px;
                    margin-left: 65px;
                    .java {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                    .neo4j {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            margin-right: 10px;
                            width: 30px;
                        }
                        span {
                            color: #202249;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0.62px;
                            line-height: 22px;
                        }
                    }
                }
                .technologie-4 {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    margin-bottom: 28.17px;
                    position: relative;
                    z-index: 1020;
                    margin-right: 60px;
                    .lottiefiles {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            width: 105px;
                        }
                    }
                    .invision {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            width: 130px;
                        }
                    }
                }
                .technologie-5 {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    
                    .sketch {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            width: 75px;
                        }
                    }
                    .figma {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        margin-right: 20px;
                        img {
                            width: 75px;
                        }
                    }
                    .swift {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 155px;
                        border-radius: 10px;
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                        img {
                            width: 75px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 991.9px){
        height: unset;
        padding: 0 0 30px 0;
        position: relative;
        overflow: hidden;
        //margin-top: -160px;
        
        .stack-col-right {
            position: relative;
            //height: 270px;
            max-width: 992px;
            padding-top: 0;
            z-index: 0;
            display: flex;
            .deisgn-element-5 {
                margin-right: -100px;
                position: unset;
                transform: unset;
                height: 317.92px;
                width: 341.17px;
            }
            .design-element-3 {
                position: absolute;
                top: 25px;
                left: -70px;
                height: 31px;
            }
            .design-element-4 {
                position: absolute;
                bottom: 45px;
                right: 125px;
                transform: unset;
                z-index: 0;
                height: 66px;
            }
           
        }
        .technologie-1 {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-bottom: 28.17px;
            .javascript {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                opacity: 1;
                img {
                    margin-right: 5px;
                    width: 25px;
                }
                span {
                    color: #202249;
                    font-size: 16px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .kotlin {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                img {
                    max-width: 100px;
                }
            }
            .unity {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                opacity: 1;
                img {
                    max-width: 100px;
                }
            }
            .lottiefiles {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                img {
                    max-width: 100px;
                }
            }
        }
        .technologie-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 28.17px;
            .springboot {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                img {
                    margin-right: 5px;
                    width: 25px;
                }
                span {
                    color: #202249;
                    font-size: 16px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .reactjs {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                img {
                    margin-right: 5px;
                    width: 25px;
                }
                span {
                    color: #202249;
                    font-size: 16px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .invision {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                margin-right: 15px;
                background-color: #FFFFFF;
                box-shadow: none;
                img {
                    max-width: 100px;
                }
            }
            .sketch {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                img {
                    max-width: 100px;
                }
            }
        }
        .technologie-3 {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-bottom: 28.17px;
            margin-left: 0;
            .java {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                img {
                    margin-right: 5px;
                    width: 25px;
                }
                span {
                    color: #202249;
                    font-size: 16px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .neo4j {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                img {
                    margin-right: 5px;
                    width: 25px;
                }
                span {
                    color: #202249;
                    font-size: 16px;
                    font-weight: 800;
                    letter-spacing: 0.62px;
                    line-height: 22px;
                }
            }
            .figma {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 5px;
                border-radius: 13px;
                margin-right: 15px;
                background-color: #FFFFFF;
                box-shadow: none;
                img {
                    max-width: 100px;
                }
            }
            .swift {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: unset !important;
                padding: 0 15px;
                border-radius: 13px;
                background-color: #FFFFFF;
                box-shadow: none;
                margin-right: 15px;
                img {
                    max-width: 100px;
                }
            }
        }
        .top-text {
            //height: 44px;
            width: 153px;
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            //font-family: Manrope;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
            //display: inline-flex;
        }
        .middle-text {
            //height: 66px;
            width: 100%;
            color: #202249;
            //font-family: Manrope;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 40px;
            //display: inline-flex;
        }
        .bottom-text {
            //height: 210px;
            width: 100%;
            color: #202249;
            //font-family: Manrope;
            font-size: 16px;
            letter-spacing: 0.36px;
            line-height: 30px;
            text-align: justify;
            //display: inline-flex;
        }
    }

}

.references-div {
    position: relative;
    .design-element-15 {
        width:44%

    }
    .design-element-16 {
        width:44%
    }
    .design-element-17 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 271px;
    }
    span {
        height: 150px;
        max-width: 278px;
        color: #202249;
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 150px;
        text-align: center;
      }
      @media (max-width: 991.9px){
        .design-element-17 {
            height: 185px;
            top: 45%;
            z-index: 0;
        }
        .design-element-15 {
            width:50%
    
        }
        .design-element-16 {
            width:50%
        }
      }
}

.fourth-section {
    //height: 700px;
    padding:  0 60px;
    position: relative;
    overflow: hidden;
    background-color: #E8ECEE;
    border-radius: 30px;
   //margin-bottom
   .title-part {
        position: relative;
        height: 150px;
        z-index: 1020;
        padding-left: 50px;
        span {
            height: 66px;
            //width: 961px;
            color: #202249;
            font-size: 48px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 66px;
            text-align: center;
        }
        .design-element-4 {
            position: absolute;
            top: 75px;
            left: -10px;
            z-index: -1;
            height: 92px;
        }
    }
    .title-text-2 {
        height: 110px;
        span {
            width: 279px;
            background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
            background-clip: border-box;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            //font-family: Manrope;
            font-size: 48px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 80px;
            text-align: center;
        }
    }
    .top-section {
        //padding-bottom: 50px;
        width: 100%;
        .link-text {
            height: 22px;
            a {
                background: -webkit-linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.42px;
                line-height: 22px;
                text-align: center;
            }
        }
        .title-text {
            height: 66px;
            span {
                width: 279px;
                color: #202249;
                //font-family: Manrope;
                font-size: 36px;
                font-weight: 600;                
                letter-spacing: 0;
                line-height: 66px;
                text-align: center;
            }
        }
        
        .description-text {
            height: 155px;
            span {
                width: 700px;
                color: #202249;
                //font-family: Manrope;
                font-size: 18px;
                letter-spacing: 0.36px;
                line-height: 32px;
                text-align: center;
            }
        }
    }

    .reference-dscripton {
        .first-element {
            height: 70px;
            img {
                height: 50px;
            }
        }
        .second-element {
            height: 50px;
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 0.44px;
                line-height: 32px;
            }
        }
        .third-element {
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 16px;
                letter-spacing: 0.32px;
                line-height: 28px;
                text-align: right;
            }
        }
        .fourth-element {
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 16px;
                letter-spacing: 0.32px;
                line-height: 28px;
                text-align: left;
            }
        }
    }
    .reference-card-col {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .deisgn-element-6 {
            width: 100%;
        }
        
        .reference-card {
            height: 597px;
            width: 307px;
            border-radius: 20px;
            background-color: #FFFFFF;
            box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
        }
    }
   
    @media (min-width: 992px) and (max-width: 1520px){
        .top-section {
            .title-text {
                height: 66px;
                span {
                    font-size: 44px;
                }
            }
            .description-text {
                height: 64px;
                span {
                    font-size: 16px;
                }
            }
        }
        .reference-dscripton {
            .first-element {
                height: 70px;
                img {
                    height: 50px;
                }
            }
            .second-element {
                height: 40px;
                span {
                    font-size: 20px;
                    line-height: 32px;
                }
            }
            .third-element {
                span {
                    font-size: 14px;
                }
            }
            .fourth-element {
                span {
                    font-size: 14px;
                }
            }
        }
        .reference-card-col {
            .deisgn-element-6 {
                width: 415px;
            }
        }
    }

    @media (max-width: 991.9px){
        padding: 30px 0 0 0;
        position: relative;
        overflow-x: hidden;
        background-color: #E8ECEE;
        border-radius: 0;
        .top-section {
            padding-bottom: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .collapse-div {
                height: 550px;
                .link-text {
                    height: 22px;
                    a {
                        background: -webkit-linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        //font-family: Manrope;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.42px;
                        line-height: 22px;
                        text-align: center;
                    }
                }
                .title-text {
                    height: 66px;
                    span {
                        width: 100%;
                        color: #202249;
                        //font-family: Manrope;
                        font-size: 48px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 66px;
                        text-align: center;
                    }
                }
                img {
                    width: 100%;
                    max-height: 436.67px;
                    max-width: 393px;
                }
                .interb2bike-details {
                    overflow-y: hidden;
                   
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .description {
                        display: flex;
                        padding-top: 10px;
                        span {
                            color: #202249;
                            font-size: 12px;
                            letter-spacing: 0.28px;
                            line-height: 16px;
                            text-align: left;
                            max-width: 332px;
                        }
                    }
                    .reference-dscripton-right {
                        max-width: 500px;
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                        padding-top: 15px;
                        .title{
                            color: #202249;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 0.36px;
                            line-height: 30px;
                        }
                        img {
                            height: 30px;
                            width: 30px;
                            margin-left: 5px;
                        }
                        .description {
                            display: flex;
                            justify-content: end;
                            padding-top: 10px;
                            span {
                                color: #202249;
                                font-size: 12px;
                                letter-spacing: 0.28px;
                                line-height: 16px;
                                text-align: right;
                                max-width: 332px;
                            }
                        }
                    }
                    .reference-dscripton-left {
                        max-width: 500px;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        padding-top: 15px;
                        .title{
                            color: #202249;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 0.36px;
                            line-height: 30px;
                        }
                        img {
                            height: 30px;
                            width: 30px;
                            margin-right: 5px;
                        }
                        .description {
                            display: flex;
                            justify-content: start;
                            padding-top: 10px;
                            span {
                                color: #202249;
                                font-size: 12px;
                                letter-spacing: 0.28px;
                                line-height: 16px;
                                text-align: left;
                                max-width: 332px;
                            }
                            
                        }
                       
                    }
                }
            }
           
           
           
        }

        .tech-details-button {
            width: 100%;
            height: 50px;
            max-width: 231px;
            background-color: #FF6701;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: none !important;
            border: none;
            border-radius: 25px;
            span {
                //height: 32px;
                //width: 201.25px;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
}

.fifth-section {
    //height: 700px;
    padding: 60px;
    position: relative;
    overflow: hidden;
  
    .top-section {
        padding-bottom: 70px;
        .link-text {
            height: 22px;
            a {
                background: -webkit-linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.42px;
                line-height: 22px;
                text-align: center;
            }
        }
        .title-text {
            height: 66px;
            span {
                width: 300px;
                color: #202249;
                //font-family: Manrope;
                font-size: 48px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 66px;
                text-align: center;
            }
        }
        .description-text {
            height: 80px;
            span {
                width: 616px;
                color: #202249;
                //font-family: Manrope;
                font-size: 18px;
                letter-spacing: 0.36px;
                line-height: 25px;
                text-align: center;
            }
        }
    }
    .reference-dscripton {
        .first-element {
            height: 70px;
            img {
                height: 50px;
            }
        }
        .second-element {
            height: 65px;
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 0.44px;
                line-height: 25px;
            }
        }
        .third-element {
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 16px;
                letter-spacing: 0.32px;
                line-height: 28px;
                text-align: right;
            }
        }
        .fourth-element {
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 16px;
                letter-spacing: 0.32px;
                line-height: 28px;
                text-align: left;
            }
        }
    }
    .reference-card-col {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .deisgn-element-6 {
            width: 100%;
        }
        .reference-card {
            height: 597px;
            width: 307px;
            border-radius: 20px;
            background-color: #FFFFFF;
            box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
        }
    }
    @media (min-width: 992px) and (max-width: 1520px){
        .top-section {
            .title-text {
                height: 66px;
                span {
                    font-size: 44px;
                }
            }
            .description-text {
                height: 64px;
                span {
                    font-size: 16px;
                }
            }
        }
        .reference-dscripton {
            .first-element {
                height: 70px;
                img {
                    height: 50px;
                }
            }
            .second-element {
                height: 40px;
                span {
                    font-size: 20px;
                    line-height: 32px;
                }
            }
            .third-element {
                span {
                    font-size: 14px;
                }
            }
            .fourth-element {
                span {
                    font-size: 14px;
                }
            }
        }
        .reference-card-col {
            .deisgn-element-6 {
                width: 415px;
            }
        }
    }
}

.sixth-section {
    //height: 700px;
    padding: 60px;
    position: relative;
    overflow: hidden;
    background-color: #E8ECEE;
    border-radius: 30px;
    .top-section {
        padding-bottom: 30px;
        .link-text {
            height: 22px;
            a {
                background: -webkit-linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.42px;
                line-height: 22px;
                text-align: center;
            }
        }
        .title-text {
            height: 66px;
            span {
                width: 279px;
                color: #202249;
                //font-family: Manrope;
                font-size: 48px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 66px;
                text-align: center;
            }
        }
        .description-text {
            height: 105px;
            span {
                width: 616px;
                color: #202249;
                //font-family: Manrope;
                font-size: 18px;
                letter-spacing: 0.36px;
                line-height: 32px;
                text-align: center;
            }
        }
    }
    .reference-dscripton {
        .first-element {
            height: 70px;
            img {
                height: 50px;
            }
        }
        .second-element {
            height: 50px;
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 0.44px;
                line-height: 32px;
            }
        }
        .third-element {
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 16px;
                letter-spacing: 0.32px;
                line-height: 28px;
                text-align: right;
            }
        }
        .fourth-element {
            span {
                color: #202249;
                //font-family: Manrope;
                font-size: 16px;
                letter-spacing: 0.32px;
                line-height: 28px;
                text-align: left;
            }
        }
    }
    .reference-card-col {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .deisgn-element-6 {
            width: 100%;
        }
        .reference-card {
            height: 597px;
            width: 307px;
            border-radius: 20px;
            background-color: #FFFFFF;
            box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
        }
    }
    @media (min-width: 992px) and (max-width: 1520px){
        .top-section {
            .title-text {
                height: 66px;
                span {
                    font-size: 44px;
                }
            }
            .description-text {
                height: 64px;
                span {
                    font-size: 16px;
                }
            }
        }
        .reference-dscripton {
            .first-element {
                height: 70px;
                img {
                    height: 50px;
                }
            }
            .second-element {
                height: 40px;
                span {
                    font-size: 20px;
                    line-height: 32px;
                }
            }
            .third-element {
                span {
                    font-size: 14px;
                }
            }
            .fourth-element {
                span {
                    font-size: 14px;
                }
            }
        }
        .reference-card-col {
            .deisgn-element-6 {
                width: 415px;
            }
        }
    }
}

.seventh-section {
    padding: 0;
    //overflow: hidden;
    .references-card {
        padding: 30px;
        margin-right: 16px;
        background-color: rgb(246, 248, 249);
        box-shadow: none;
        .top-section {
            padding-bottom: 60px;
            .link-text {
                height: 35px;
                a {
                    background: -webkit-linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    //font-family: Manrope;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.42px;
                    line-height: 22px;
                    text-align: center;
                }
            }
            .title-text {
                //height: 66px;
                padding-bottom: 5px;
                span {
                    width: 279px;
                    color: #202249;
                    //font-family: Manrope;
                    font-size: 48px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 48px;
                    text-align: center;
                }
            }
            .description-text {
                height: 105px;
                span {
                    width: 460px;
                    color: #202249;
                    //font-family: Manrope;
                    font-size: 18px;
                    letter-spacing: 0.36px;
                    line-height:25px;
                    text-align: center;
                }
            }
        }
        .first-col {
            position: relative;
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
        .second-col {
            position: relative;
            
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
        .third-col {
            position: relative;
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
    }
    .references-card-1 {
        padding: 30px;
        margin-right: 8px;
        margin-left: 8px;
        background-color: rgb(246, 248, 249);
        box-shadow: none;
        .top-section {
            padding-bottom: 60px;
            .link-text {
                height: 35px;
                a {
                    background: -webkit-linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    //font-family: Manrope;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.42px;
                    line-height: 22px;
                    text-align: center;
                }
            }
            .title-text {
                //height: 66px;
                padding-bottom: 5px;
                span {
                    width: 279px;
                    color: #202249;
                    //font-family: Manrope;
                    font-size: 48px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 48px;
                    text-align: center;
                }
            }
            .description-text {
                height: 105px;
                span {
                    width: 460px;
                    color: #202249;
                    //font-family: Manrope;
                    font-size: 18px;
                    letter-spacing: 0.36px;
                    line-height:25px;
                    text-align: center;
                }
            }
        }
        .first-col {
            position: relative;
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
        .second-col {
            position: relative;
            
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
        .third-col {
            position: relative;
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
    }
    .references-card-2 {
        padding: 30px;
        margin-left: 16px;
        background-color: rgb(246, 248, 249);
        box-shadow: none;

        .top-section {
            padding-bottom: 60px;
            .link-text {
                height: 35px;
                a {
                    background: -webkit-linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    //font-family: Manrope;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.42px;
                    line-height: 22px;
                    text-align: center;
                }
            }
            .title-text {
                //height: 66px;
                padding-bottom: 5px;
                span {
                    width: 279px;
                    color: #202249;
                    //font-family: Manrope;
                    font-size: 48px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 48px;
                    text-align: center;
                }
            }
            .description-text {
                height: 105px;
                span {
                    width: 460px;
                    color: #202249;
                    //font-family: Manrope;
                    font-size: 18px;
                    letter-spacing: 0.36px;
                    line-height:25px;
                    text-align: center;
                }
            }
        }
        .first-col {
            position: relative;
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
        .second-col {
            position: relative;
            
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
        .third-col {
            position: relative;
            .reference-card {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 418px;
                max-width: 322px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
            }
        }
    }

    @media (min-width: 992px) and (max-width: 1520px){
        .references-card {
            border-radius: 30px;
            padding: 15px;
            margin-right: 12px;
            .top-section {
                padding-bottom: 20px;
                .title-text {
                    span {
                        width: 279px;
                        color: #202249;
                        //font-family: Manrope;
                        font-size: 38px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 66px;
                        text-align: center;
                    }
                }
                .description-text {
                    height: 150px;
                    span {
                        width: 100%;
                        color: #202249;
                        //font-family: Manrope;
                        font-size: 14px;
                        letter-spacing: 0.36px;
                        line-height: 25px;
                        text-align: center;
                    }
                }
            }
        }
        .references-card-1 {
            border-radius: 30px;
            padding: 15px;
            margin-right: 6px;
            margin-left: 6px;
            .top-section {
                padding-bottom: 20px;
                .title-text {
                    span {
                        width: 279px;
                        color: #202249;
                        //font-family: Manrope;
                        font-size: 38px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 66px;
                        text-align: center;
                    }
                }
                .description-text {
                    height: 150px;
                    span {
                        width: 100%;
                        color: #202249;
                        //font-family: Manrope;
                        font-size: 14px;
                        letter-spacing: 0.36px;
                        line-height: 25px;
                        text-align: center;
                    }
                }
            }
        }
        .references-card-2 {
            border-radius: 30px;
            padding: 15px;
            margin-left: 12px;
            .top-section {
                padding-bottom: 20px;
                .title-text {
                    span {
                        width: 279px;
                        color: #202249;
                        //font-family: Manrope;
                        font-size: 38px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 66px;
                        text-align: center;
                    }
                }
                .description-text {
                    height: 150px;
                    span {
                        width: 100%;
                        color: #202249;
                        //font-family: Manrope;
                        font-size: 14px;
                        letter-spacing: 0.36px;
                        line-height: 25px;
                        text-align: center;
                    }
                }
            }
        }
    }
    
}

.eighth-section{
    width: 100%;
	background-color: #E8ECEE;
	position: relative;
    max-height: 1100px;
    overflow: hidden;
    .description-text-telefon {
        position: absolute;
        top: 90px;
        Left: 50%;
        transform: translateX(-50%);
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.42px;
        line-height: 22px;
        z-index: 1040;
    }
    .footer-background {
        height: auto;
        min-height: 900px;
        width: 100%;
        position: absolute;
        z-index: 1020;
    }
    .top-col-left {
        position: relative;
        z-index: 1040;
    }
    
    .top-col-right {
        position: relative;
        z-index: 1040;
        button {
            margin: 0;
            padding: 15px 30px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
    
    .middle-col-left {
        
        z-index: 1100;
        //height: 700px;
        padding-top: 200px;
        margin-bottom: 100px;
        .title-text {
            //width: 493px;
            color: #FFFFFF;
            //font-family: Manrope;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 45px;
        }
        .description-text {
            //width: 159px;
            color: #FFFFFF;
            //font-family: Manrope;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
        }
        button {
            width: auto;
            margin: 0;
            padding: 18px 50px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
    
    .interb2b-logo-col {
        position: relative;
        z-index: 1040;
        //padding-top: 235px;
        .design-element-1 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-left: -141px;
            margin-top: -172px;
        }
        .design-element-14 {
            //position: absolute;
            //top: 0;
            //left: 0;
            width: 1000px;
            margin-top: 150px;
            //transform: translate(-50%,-32.3%);
        }
        .orange-circle-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-top: 275px;
            margin-left: 130px;
        }
        .cover-shadow-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-top:  300px;
        }
    }
    input {
        box-sizing: border-box;
        height: 59px;
        width: 100%;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        background: transparent; 
        outline: none;
        padding: 18px 17px;
        //font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 23px;
        color: #FFFFFF;
      
      
    }
      
    label {
        margin-bottom: 10px;
        opacity: 0.62;
        color: #FFFFFF;
        //font-family: Manrope;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.48px;
    }
    textarea {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        background: transparent; 
        outline: none;
        padding: 18px 17px;
        //font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 23px;
        color: #FFFFFF;
    }

    .footer-navbar {
        position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 2000;
        .fixed-footer-navbar{
            .navbar {
            height: 115px !important;
            width: 100% !important;
            background: transparent !important;
            opacity: 0.8;
            z-index: 2000;
            padding-bottom: 0;
            padding-top:0;
            box-shadow: none;
            }
            .navbar-light .navbar-nav {
            display: table;
            float: right !important;
            a {
                position: relative;
                width: 155px;
                margin-right: 30px;
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
                opacity: 0.8;
                
                span {
                    font-weight: 500!important;
                    letter-spacing: 1.6px!important;
                
                }
        
                &:hover {
                    opacity: 1;
                    color: #ffffff !important;
                    font-weight: 700!important;
                }
        
                
            }
        }
        }
        .logotext-footer {
            //width: 186px;
            color: #FFFFFF;
            //font-family: Manrope;
            font-size: 16px;
            letter-spacing: 0.42px;
            //line-height: 72px;  
        }
    }
    
    @media (min-width: 992px) and (max-width: 1520px){
        .middle-col-left {
            .title-text {
                font-size: 26px;
                line-height: 35px;
            }
            .description-text {
                font-size: 14px;
                line-height: 20px;
            }
            button {
                padding: 10px 30px;
                border-radius: 20px;
                span {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
        .interb2b-logo-col {
            position: relative;
            z-index: 1040;
            .design-element-1 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                margin-left: -141px;
                margin-top: -172px;
            }
            .design-element-14 {
                //transform: translate(-50%, -44.3%);
                width: 720px;
            }
            .orange-circle-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                margin-top: 275px;
                margin-left: 130px;
            }
            .cover-shadow-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                margin-top:  300px;
            }
        }
        input {
            height: 48px;
            border-radius: 15px;
            font-size: 14px;
            line-height: 16px;
            padding: 10px 17px;
        }
          
        label {
            margin-bottom: 5px;
            font-size: 14px;
        }
        textarea {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #FFFFFF;
            border-radius: 20px;
            background: transparent; 
            outline: none;
            padding: 10px 17px;
            font-size: 14px;
            line-height: 17px;
        }
        .footer-navbar {
            .fixed-footer-navbar{
                .navbar {
                height: 55px !important;
                }
            }
           
        }
    }
    @media (max-width: 991.9px){
    .description-text-telefon {
        position: absolute;
        top: 90px;
        Left: 50%;
        transform: translateX(-50%);
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.42px;
        line-height: 22px;
        z-index: 1040;
    }
    .footer-background {
        min-height: unset;
        bottom: 0;
        height: 100%;
    }
    .top-col-left {
        position: relative;
        z-index: 1040;
    }
    
    .top-col-right {
        position: relative;
        z-index: 1040;
        button {
            margin: 0;
            padding: 15px 30px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
    
    .middle-col-left {
        
        z-index: 1100;
        //height: 700px;
        padding-top: 70px;
        margin-bottom: 20px;
        .title-text {
            //width: 493px;
            color: #FFFFFF;
            //font-family: Manrope;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 45px;
        }
        .description-text {
            //width: 159px;
            color: #FFFFFF;
            //font-family: Manrope;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.42px;
            line-height: 22px;
        }
        button {
            width: auto;
            margin: 0;
            padding: 18px 50px;
            background-color: #FF6701;
            box-shadow: none !important;
            border-radius: 30px;
            cursor: pointer;
            border: none;
            span {
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
    
    .interb2b-logo-col {
        position: relative;
        z-index: 1040;
        //padding-top: 235px;
        .design-element-1 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-left: -141px;
            margin-top: -172px;
        }
        .design-element-14 {
            //position: absolute;
            //top: 0;
            //left: 0;
            width: 1000px;
            margin-top: 150px;
            //transform: translate(-50%,-32.3%);
        }
        .orange-circle-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-top: 275px;
            margin-left: 130px;
        }
        .cover-shadow-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-top:  300px;
        }
    }
    input {
        box-sizing: border-box;
        height: 59px;
        width: 100%;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        background: transparent; 
        outline: none;
        padding: 18px 17px;
        //font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 23px;
        color: #FFFFFF;
      
      
    }
      
    label {
        margin-bottom: 10px;
        opacity: 0.62;
        color: #FFFFFF;
        //font-family: Manrope;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.48px;
    }
    textarea {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        background: transparent; 
        outline: none;
        padding: 18px 17px;
        //font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 23px;
        color: #FFFFFF;
    }

    .footer-navbar {
        position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 2000;
        .fixed-footer-navbar{
            .navbar {
            height: 115px !important;
            width: 100% !important;
            background: transparent !important;
            opacity: 0.8;
            z-index: 2000;
            padding-bottom: 0;
            padding-top:0;
            box-shadow: none;
            }
            .navbar-light .navbar-nav {
            display: table;
            float: right !important;
            a {
                position: relative;
                width: 155px;
                margin-right: 30px;
                color: #FFFFFF;
                //font-family: Manrope;
                font-size: 16px;
                letter-spacing: 0.48px;
                line-height: 17px;
                text-align: center;
                opacity: 0.8;
                
                span {
                    font-weight: 500!important;
                    letter-spacing: 1.6px!important;
                
                }
        
                &:hover {
                    opacity: 1;
                    color: #ffffff !important;
                    font-weight: 700!important;
                }
        
                
            }
        }
        }
        .logotext-footer {
            //width: 186px;
            color: #FFFFFF;
            //font-family: Manrope;
            font-size: 16px;
            letter-spacing: 0.42px;
            //line-height: 72px;  
        }
    }
    
    }
    
}

.ninth-section {
    height: 830px;
    //padding: 60px;
    margin-top: 50px;
    position: relative;
    //overflow: hidden;
    .deisgn-element-5 {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-20%, -57%);
        height: 450px;
    }
    .design-element-3 {
        height: 31px;
    }
    .design-element-15 {
        height: 645px;
    }
    .about-col-left {
        position: relative;
        height: 100%;
        .design-element-3 {
            position: absolute;
            top: 15px;
            left: -30px;
        }
    }
    .about-col-right {
        position: relative;
        max-width: 680px;
        z-index: 1020;
        margin-left: 50px;
        .design-element-4 {
            position: absolute;
            top: -50px;
            right: -30px;
            z-index: 1000;
        }
        .partner-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 28.17px;
            .appentum {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 250px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                transition: all .3s ease-in-out;
                img {
                    width: 222.5px;
                    margin-right: -15px;
                }
                &:hover {
                    box-shadow: 0 0px 5px 4px rgba(3, 96, 236, 0.5);
                }
            }
        }
        .partner-1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 28.17px;
            margin-top: 50px;
            .interb2b {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 300px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                transition: all .3s ease-in-out;
                cursor: pointer;
                img {
                      //width: 139.5px;
                }
                &:hover {
                    box-shadow: 0 0px 5px 4px rgba(3, 96, 236, 0.5);
                }
            }
            .studicore {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 300px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                transition: all .3s ease-in-out;
                img {
                      //width: 204px;
                }
                &:hover {
                    box-shadow: 0 0px 5px 4px rgba(3, 96, 236, 0.5);
                }
            }
            .appentum {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 300px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                transition: all .3s ease-in-out;
                img {
                    //width: 222.5px;
                    margin-right: -15px;
                }
                &:hover {
                    box-shadow: 0 0px 5px 4px rgba(3, 96, 236, 0.5);
                }
            }
            .itboda {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 300px;
                //border-radius: 20px;
                //background-color: #FFFFFF;
                //box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                img {
                    width: 97.14px;
                }
            }
        }
        .partner-3 {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-bottom: 28.17px;
            .itboda {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 81px;
                width: 173px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                img {
                    width: 97.14px;
                }
            }
            
        }
      
    }
    
    .top-text {
        width: 150px;
        background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        //font-family: Manrope;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.42px;
        line-height: 22px;
    }
    .middle-text {
        width: 329px;
        color: #202249;
        //font-family: Manrope;
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 66px;
    }
    .bottom-text {
        width: 472px;
        color: #202249;
        //font-family: Manrope;
        font-size: 18px;
        letter-spacing: 0.36px;
        line-height: 32px;
    }
    @media (min-width: 992px) and (max-width: 1520px){
        height: 700px;
        .deisgn-element-5 {
            transform: translate(-20%, -50%);
            height: 450px;
        }
        .design-element-3 {
            height: 31px;
        }
        .design-element-15 {
            height: 590px;
            margin-left: -25px;
        }
        .middle-text {
            width: 380px;
            font-size: 36px;
            line-height: 55px;
            img {
                top: -100px;
                right: -6px;
                height: 77px;
            }
        }
        .bottom-text {
            width: 380px;
            font-size: 14px;
            line-height: 28px;
        }
        .about-col-right {
            margin-left: 0;
            padding-left: 60px !important;
            .design-element-4 {
                position: absolute;
                top: -35px;
                right: 10px;    
                z-index: 1000;
            }
            .partner-2 {
                .appentum {
                    height: 70px;
                    width: 205px;
                    border-radius: 20px;
                    img {
                        width: 188.5px;
                        margin-right: -15px;
                    }
                }
            }
            .partner-1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 28.17px;
                margin-top: 50px;
                .interb2b {
                    height: 70px;
                    width: 200px;
                    border-radius: 20px;
                    img {
                          width: 122.5px;
                    }
                }
                .studicore {
                    height: 70px;
                    width: 200px;
                    border-radius: 20px;
                    img {
                          width: 190px;
                    }
                }
            }
            .partner-3 {
                display: flex;
                align-items: center;
                justify-content: start;
                margin-bottom: 28.17px;
                .itboda {
                    height: 70px;
                    width: 145px;
                    border-radius: 20px;
                    img {
                        width: 80.14px;
                    }
                }
                
            }
          
        }
    }

    @media (max-width: 991.9px){
        margin-top: 0;
        height: 100%;
        .about-col-right {
            position: relative;
            max-width: unset;
            z-index: 1020;
            margin-left: 0;
            .design-element-4 {
                position: absolute;
                top: -85px;
                right: -30px;
                z-index: 1000;
            }
            .partner-1 {
                display: flex;
                flex-direction: column;
                //align-items:;
                justify-content: center;
                margin-bottom: 28.17px;
                margin-top: 50px;
                .interb2b {
                    max-width: 540px;
                    margin-bottom: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 100%;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                    transition: all .3s ease-in-out;
                    cursor: pointer;
                    img {
                          //width: 139.5px;
                    }
                    &:hover {
                        box-shadow: 0 0px 5px 4px rgba(3, 96, 236, 0.5);
                    }
                }
                .studicore {
                    max-width: 540px;
                    margin-bottom: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 100%;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                    transition: all .3s ease-in-out;
                    img {
                          //width: 204px;
                    }
                    &:hover {
                        box-shadow: 0 0px 5px 4px rgba(3, 96, 236, 0.5);
                    }
                }
                .appentum {
                    max-width: 540px;
                    margin-bottom: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 100%;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                    transition: all .3s ease-in-out;
                    img {
                        //width: 222.5px;
                        margin-right: -15px;
                    }
                    &:hover {
                        box-shadow: 0 0px 5px 4px rgba(3, 96, 236, 0.5);
                    }
                }
                .itboda {
                    max-width: 540px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 81px;
                    width: 100%;
                    //border-radius: 20px;
                    //background-color: #FFFFFF;
                    //box-shadow: 0 0px 5px 4px rgba(0,0,0,0.05);
                    img {
                        width: 97.14px;
                    }
                }
            }
          
        }
        .bottom-text {
            width: 300px;
        }
    }

}

.service-section {
    height: 850px;
    padding: 90px 60px 50px 60px;
    background-color: #E8ECEE;
    
    position: relative;
    overflow: hidden;
    .third-container {
        position: relative;
    }
    .deisgn-element-5 {
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translate(-20%, -45%);
        height: 500px;
    }
    .stack-col-right {
        position: relative;
        max-width: 900px;
        z-index: 1020;
        .design-element-3 {
            position: absolute;
            top: 25px;
            left: 225px;
            height: 35px;
        }
        .design-element-4 {
            position: absolute;
            bottom: 125px;
            right: 109px;
            transform: translate(119%, 102%);
            z-index: 1000;
            height: 92px;
        }
        .technologie-1 {
            //display: flex;
            //align-items: center;
            //justify-content: center;
            margin-bottom: 28.17px;
            margin-left: 55px;
            .mobile-apps {
                height: 180px;
                width: 392px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                padding: 25px 32px;
                .title-part {
                    display: flex;
                    align-items:center;
                    span {
                        //width: 320px;
                        color: #202249;
                        font-size: 32px;
                        font-weight: 700;
                        letter-spacing: 0.83px;
                        //line-height: 22px; 
                    }
                    img {
                        width: 32px;
                        margin-right: 10px;
                    }
                }
                .description-part {
                    color: #202249;
                    font-size: 18px;
                    letter-spacing: 0.36px;
                    line-height: 28px;
                    text-align: left;
                }
            }
        }
        .technologie-2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 28.17px;
            margin-left: 55px;
            .mobile-apps {
                height: 210px;
                width: 400px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                padding: 25px 32px;
                .title-part {
                    display: flex;
                    align-items:center;
                    span {
                        //width: 320px;
                        color: #202249;
                        font-size: 32px;
                        font-weight: 700;
                        letter-spacing: 0.83px;
                        //line-height: 22px; 
                    }
                    img {
                        width: 32px;
                        margin-right: 10px;
                    }
                }
                .description-part {
                    color: #202249;
                    font-size: 18px;
                    letter-spacing: 0.36px;
                    line-height: 28px;
                    text-align: left;
                }
            }
        }
        .technologie-3 {
            //display: flex;
            //align-items: center;
            //justify-content: center;
            margin-bottom: 28.17px;
            margin-left: 55px;
            .mobile-apps {
                height: 158px;
                width: 400px;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                padding: 25px 32px;
                .title-part {
                    display: flex;
                    align-items:center;
                    span {
                        //width: 320px;
                        color: #202249;
                        font-size: 32px;
                        font-weight: 700;
                        letter-spacing: 0.83px;
                        //line-height: 22px; 
                    }
                    img {
                        width: 32px;
                        margin-right: 10px;
                    }
                }
                .description-part {
                    color: #202249;
                    font-size: 18px;
                    letter-spacing: 0.36px;
                    line-height: 28px;
                    text-align: left;
                }
            }
        }
    }
    
    .top-text {
        width: 153px;
        background: -webkit-linear-gradient(rgb(116, 117, 140), rgb(32, 34, 73));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.42px;
        line-height: 22px;
    }
    .middle-text {
        width: 534px;
        color: #202249;
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 66px;
    }
    @media (min-width: 992px) and (max-width: 1520px){
        padding: 60px 20px;
        .middle-text {
            width: 380px;
            font-size: 36px;
            line-height: 55px;
            img {
                top: -100px;
                right: -6px;
                height: 77px;
            }
        }
        .bottom-text {
            width: 380px;
            font-size: 14px;
            line-height: 28px;
        }
        .deisgn-element-5 {
            transform: translate(0, -40%);
            height: 430px;
            right: 75px;
        }
        .stack-col-right {
            position: relative;
            max-width: 900px;
            z-index: 1020;
           // margin-left: -50px;
            .design-element-3 {
                position: absolute;
                top: 25px;
                right: 495px;
                left: unset;
                height: 35px;
            }
            .design-element-4 {
                position: absolute;
                bottom: 125px;
                right: 190px;
                transform: translate(119%, 102%);
                z-index: 1000;
                height: 85px;
            }
            .technologie-1 {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 28.17px;
                margin-left: 0;
                z-index: 2000;
                .mobile-apps {
                    height: 125px;
                    width: 295px;
                    border-radius: 15px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0, 0, 0, 0.05);
                    padding: 15px 15px;
                    .title-part {
                        display: flex;
                        align-items:center;
                        span {
                            //width: 320px;
                            color: #202249;
                            font-size: 24px;
                            font-weight: 700;
                            letter-spacing: 0.83px;
                            //line-height: 22px; 
                        }
                        img {
                            width: 32px;
                            margin-right: 5px;
                        }
                    }
                    .description-part {
                        margin-top: 10px;
                        color: #202249;
                        font-size: 14px;
                        letter-spacing: 0.36px;
                        line-height: 20px;
                    }
                }
            }
            .technologie-2 {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 28.17px;
                margin-left: 0;
                .mobile-apps {
                    height: 125px;
                    width: 295px;
                    border-radius: 15px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0, 0, 0, 0.05);
                    padding: 15px 15px;
                    .title-part {
                        display: flex;
                        align-items:center;
                        span {
                            //width: 320px;
                            color: #202249;
                            font-size: 24px;
                            font-weight: 700;
                            letter-spacing: 0.83px;
                            //line-height: 22px; 
                        }
                        img {
                            width: 32px;
                            margin-right: 5px;
                        }
                    }
                    .description-part {
                        margin-top: 10px;
                        color: #202249;
                        font-size: 14px;
                        letter-spacing: 0.36px;
                        line-height: 20px;
                    }
                }
            }
            .technologie-3 {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 28.17px;
                margin-left: 0;
                .mobile-apps {
                    height: 125px;
                    width: 295px;
                    border-radius: 15px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0, 0, 0, 0.05);
                    padding: 15px 15px;
                    .title-part {
                        display: flex;
                        align-items:center;
                        span {
                            //width: 320px;
                            color: #202249;
                            font-size: 24px;
                            font-weight: 700;
                            letter-spacing: 0.83px;
                            //line-height: 22px; 
                        }
                        img {
                            width: 32px;
                            margin-right: 5px;
                        }
                    }
                    .description-part {
                        margin-top: 10px;
                        color: #202249;
                        font-size: 14px;
                        letter-spacing: 0.36px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    @media (max-width: 991.9px){
        height: 100%;
        padding: 20px 10px 0 10px;
        position: relative;
        overflow: hidden;
        .deisgn-element-5 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 400px;
        }
        .stack-col-right {
            position: relative;
            max-width: 900px;
            z-index: 1020;
            .design-element-3 {
                position: absolute;
                top: 0px;
                right: 55px;
                left: unset;
                height: 35px;
                z-index: -1;
            }
            .design-element-4 {
                position: absolute;
                top: 50%;
                left: 80%;
                transform: translate(-50%, -50%);
                z-index: -1;
                height: 92px;
            }
            .technologie-1 {
                margin-bottom: 28.17px;
                margin-left: 0;
                .mobile-apps {
                    height: auto;
                    width: 392px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 15px 20px 9px rgba(0,0,0,0.05);
                    padding: 25px 32px;
                    .title-part {
                        display: flex;
                        align-items:center;
                        span {
                            //width: 320px;
                            color: #202249;
                            font-size: 22px;
                            font-weight: 700;
                            letter-spacing: 0.83px;
                            //line-height: 22px; 
                        }
                        img {
                            width: 32px;
                            margin-right: 10px;
                        }
                    }
                    .description-part {
                        color: #202249;
                        font-size: 14px;
                        letter-spacing: 0.36px;
                        line-height: 16px;
                    }
                }
            }
        }
        .top-text {
            max-width: 153px;
            width: unset;
            font-size: 16px;
            line-height: 22px;
        }
        .middle-text {
            width: 100%;
            font-size: 36px;
            line-height: 45px;
        }
    }

}

