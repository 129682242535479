
.galerie-component {
    //max-width: 100vw;
    height: 950px;
    //overflow: hidden !important;
    .carousel-container {
        height: 950px;
        display: flex;
        justify-content: left;
        margin: 0 28px;
        position: relative;
        z-index: 1000 !important;
        overflow-x: hidden !important;
        overflow-y: unset !important;
        .react-multi-carousel-item {
            width: 100%;
            transform-style: unset;
            &.active {
                    margin-right: 8px;
            }
        }
        .react-multi-carousel-track {
            height: 800px;
        }
        .react-multiple-carousel__arrow {
          position: relative;
          z-index: 10;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          height: 48px;
          padding: 0;
          margin: 0;
         
      }
  
      .react-multiple-carousel__arrow--right {
          position: absolute;
          left: calc(50% + 150px) !important;
          transform: translateX(-50%);
          bottom: 40px;
          &::before {
              content: url('../../media/images/Combined Shape.png');
              height: 48px;
          }
      }
  
      .react-multiple-carousel__arrow--left {
          position: absolute;
          bottom: 40px;
          left: calc(50% - 150px) !important;
          transform: translateX(-50%);
          &::before {
              content: url('../../media/images/Combined Shape.png');
              transform: rotate(180deg);
              height: 48px;
          }
      }
    }
    .react-multi-carousel-dot-list {
        position: absolute !important;
        bottom: 51px !important;
        display: flex !important;
        left: 50% !important;
        right: 50% !important;
        transform: translateX(-50%); 
        padding: 0 !important;
        margin: 0;
        list-style: none !important;
        text-align: center !important;
      }
    .react-multi-carousel-dot button {
        transition: opacity .25s ease-in;
        border: none!important;
        box-shadow: none;
        background-color: #E9E9E9;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        opacity: 0.9;
        cursor: pointer;
        display: inline-block;
        margin: 0 8px;
      }
      .react-multi-carousel-dot--active button {
        opacity: 1;
        height: 14px;
        width: 14px;
        background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
        border-radius: 50%;
        border: none!important;
        margin-top: -2px;
      }
}

.carousel-slide {
    .carousel .control-dots {
        position: absolute;
        bottom: unset;
        top: 22px;
        margin: 10px 0;
        padding: 0;
        text-align: center;
        width: 100%;
        z-index: 1;
    }
    .carousel .control-dots .dot.selected {
        opacity: 1;
        height: 14px;
        width: 14px;
        background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
        border-radius: 50%;
        border: none !important;
        margin-bottom: -2px;
      }
      .carousel.carousel-slider .control-arrow {
        opacity: 0;
      }
}

.carousel-slide-2 {
    height: 700px;
    overflow: hidden !important;
    .carousel-container {
        height: 700px;
        display: flex;
        justify-content: left;
        margin: 0 28px;
        position: relative;
        z-index: 1000 !important;
        overflow-x: hidden !important;
        overflow-y: unset !important;
        .react-multi-carousel-item {
            width: 100%;
            transform-style: unset;
            &.active {
                    margin-right: 8px;
            }
        }
        .react-multi-carousel-track {
            height: 700px;
        }
        .react-multiple-carousel__arrow {
          position: relative;
          z-index: 10;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          height: 48px;
          padding: 0;
          margin: 0;
         
      }
  
      .react-multiple-carousel__arrow--right {
          position: absolute;
          left: calc(50% + 150px) !important;
          transform: translateX(-50%);
          bottom: 40px;
          &::before {
              content: url('../../media/images/Combined Shape.png');
              height: 48px;
          }
      }
  
      .react-multiple-carousel__arrow--left {
          position: absolute;
          bottom: 40px;
          left: calc(50% - 150px) !important;
          transform: translateX(-50%);
          &::before {
              content: url('../../media/images/Combined Shape.png');
              transform: rotate(180deg);
              height: 48px;
          }
      }
    }
    .react-multi-carousel-dot-list {
        position: absolute !important;
        bottom: 85px !important;
        display: flex !important;
        left: 50% !important;
        right: 50% !important;
        transform: translateX(-50%); 
        padding: 0 !important;
        margin: 0;
        list-style: none !important;
        text-align: center !important;
        z-index: 2000;
      }
    .react-multi-carousel-dot button {
        transition: opacity .25s ease-in;
        border: none!important;
        box-shadow: none;
        background-color: #A29898;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        opacity: 0.9;
        cursor: pointer;
        display: inline-block;
        margin: 0 8px;
      }
      .react-multi-carousel-dot--active button {
        opacity: 1;
        height: 14px;
        width: 14px;
        background: linear-gradient(224.59deg, #00C6FA 0%, #0360EC 100%);
        border-radius: 50%;
        border: none!important;
        margin-top: -2px;
      }
}
